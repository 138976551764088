import { Flex, Text, Box } from '@chakra-ui/react';
import type { RecommendationsContent } from '@app/interfaces/PageContents';

type PortfolioRecommendationSectionProps = {
  content: RecommendationsContent;
};

export const PortfolioRecommendationSection = ({
  content,
}: PortfolioRecommendationSectionProps): JSX.Element => {
  return (
    <Box background="white" w="full">
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="center"
        maxW="71.25rem"
        margin="auto"
        p={8}
        gap={4}
      >
        <Box maxW={{ base: 'full', lg: '487px' }}>
          <Text
            color="rgb(25, 27, 27)"
            fontSize="2.1rem"
            lineHeight="3.3rem"
            fontWeight="normal"
          >
            {content.sectionTitle}
          </Text>
        </Box>
        <Flex
          direction="column"
          justifyContent="space-between"
          flex="1"
          alignItems="center"
          gap="2rem"
        >
          <Flex
            direction="column"
            gap="1rem"
            color="rgb(23, 71, 56)"
            fontSize="1.05rem"
          >
            {content.sectionDescription?.map((contentText, index) => (
              <Text key={index}>{contentText}</Text>
            ))}
          </Flex>

          <Box
            textShadow="0px 0px 1rem #174717"
            as="a"
            fill="#FFF"
            href="#topo"
            cursor="pointer"
            background="transparent"
            backgroundImage="linear-gradient(110deg, #174738 0%, #174738 67%)"
            padding="1.3rem 2rem"
            boxShadow="0 0 0.95rem 0 #2EC52B"
            borderRadius={3}
          >
            <Text
              fontWeight="bold"
              fontSize=".85rem"
              lineHeight="110%"
              textAlign="center"
              color="#FFF"
            >
              {content.buttonText}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
