import { Box, Flex, Text } from '@chakra-ui/react';
import { MdDoubleArrow } from 'react-icons/md';
import type { InvestorBenefitsContent } from '@app/interfaces/PageContents';
type InvestorBenefitsSectionProps = {
  content: InvestorBenefitsContent;
};
export const InvestorBenefitsSection = ({
  content,
}: InvestorBenefitsSectionProps): JSX.Element => {
  return (
    <Flex
      background="green.300"
      w="full"
      h="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        maxW="71.25rem"
        color="#fff"
        gap="1rem"
        direction={{ base: 'column', lg: 'row' }}
        justifyContent="center"
        p={8}
      >
        <Box
          maxW={{ base: '13rem', lg: '15rem', xl: '19rem' }}
          alignSelf="center"
        >
          <Text
            fontSize={{ base: '1.5rem', lg: '2.5rem', xl: '3.3rem' }}
            fontWeight="bold"
            textAlign="center"
            lineHeight={{ base: 'inherit', xl: '4.5rem' }}
          >
            {content.sectionTitle}
          </Text>
        </Box>

        <Flex
          maxH="27.31rem"
          flex="1"
          direction="column"
          background="#FFF"
          p={5}
          justifyContent="center"
          gap={4}
          borderRadius={7}
        >
          {content.sectionMainContent?.map((mainText, index) => (
            <Text
              key={index}
              fontSize={{ lg: 'md', xl: 'lg' }}
              color="green.300"
            >
              {mainText}
            </Text>
          ))}
        </Flex>
        <Flex direction="column" gap={4} maxW="23rem">
          {content.sectionDescriptions?.map((descriptionText, index) => (
            <Flex key={index} gap={2} alignItems="center">
              <MdDoubleArrow size={18} />
              <Text>{descriptionText}</Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
