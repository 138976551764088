import { useRouter } from 'next/router';
import { Button, Flex, Text, Image, Heading, Box } from '@chakra-ui/react';
import { BsArrowRightShort } from 'react-icons/bs';
import { VscCircleLargeFilled } from 'react-icons/vsc';

import type {
  PresentationFormContent,
  PresentationMainContent,
} from '@app/interfaces/PageContents';

type PresentationProps = {
  formContent: PresentationFormContent;
  mainContent: PresentationMainContent;
};

export const Presentation = ({
  formContent,
  mainContent,
}: PresentationProps): JSX.Element => {
  const router = useRouter();
  const handleClick = () => {
    router.push('/form', undefined, { shallow: true });
  };
  return (
    <Flex
      w="full"
      direction={{ base: 'column', lg: 'row' }}
      maxW="71.25rem"
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent="space-between"
      px={8}
      paddingBottom={16}
      gap={4}
    >
      <Flex
        color="white"
        flexDirection="column"
        alignItems={{ base: 'center', lg: 'flex-start' }}
        gap={{ base: '1rem', md: '2rem' }}
        maxW="33.56rem"
      >
        <Image
          src={mainContent.imageUrl}
          alt="eqi-logo"
          w="12.5rem"
          h="4.125rem"
          alignSelf="center"
        />
        <Flex
          textShadow="0 0 1rem #174717"
          fill="#000"
          background="transparent"
          minW="15.43rem"
          borderRadius={8}
          backgroundImage="linear-gradient(110deg,#FAE312 0%,#FAE312 67%)"
          padding="0.5rem 1rem"
          boxShadow="0px 0px 15px 0px #2EC52B"
          alignItems="end"
          gap={2}
        >
          <VscCircleLargeFilled color="red" size={18} />
          <Text
            fontWeight="500"
            fontSize=".90rem"
            lineHeight="110%"
            color="black"
          >
            {mainContent.walletTitle}
          </Text>
        </Flex>
        <Flex direction="column" gap={{ base: '1rem', lg: '1.2rem' }}>
          <Heading
            fontSize={{ base: '1.2rem', lg: '1.8rem' }}
            fontWeight="normal"
            lineHeight="1.5em"
          >
            {mainContent.sectionTitle}
          </Heading>

          {mainContent.sectionDescription?.map((text, index) => (
            <Text key={index} fontSize={{ base: '0.9rem', lg: '1.1rem' }}>
              {text}
            </Text>
          ))}
          {mainContent.startDate && <Text>{mainContent.startDate}</Text>}
          {mainContent.extraInfo && <Text>{mainContent.extraInfo}</Text>}
        </Flex>
      </Flex>
      <Box id={'topo'} cursor="auto">
        <Flex
          direction="column"
          maxW="22.75rem"
          alignItems="center"
          justifyContent="center"
          gap={4}
          background="#FFF"
          py={{ base: '2rem', lg: '3rem' }}
          px={{ base: '0', sm: '3.9rem' }}
        >
          <Image
            src={formContent.imageUrl}
            alt="eqi-finance-image"
            w="3rem"
            h="3rem"
            mb="1rem"
          />
          <Flex direction="column" gap="1rem">
            <Heading fontSize="1.5rem" textAlign="center" lineHeight="2.3rem">
              {formContent.sectionTitle}
            </Heading>
            <Text fontSize="0.8rem" textAlign="center" fontWeight="thin">
              {formContent.sectionDescription}
            </Text>
          </Flex>
          <Button
            background="rgb(23, 71, 56)"
            color="#FFF"
            px="5rem"
            onClick={handleClick}
            _hover={{
              opacity: 0.8,
            }}
          >
            <Text fontSize="1rem" fontWeight="normal" mr="2">
              {formContent.buttonText}
            </Text>
            <BsArrowRightShort size={25} />
          </Button>
          <Flex mt={{ base: 0, lg: 4 }} fontStyle="italic">
            <Text fontWeight="normal" fontSize=".65rem">
              🔒 Fique tranquilo, não enviamos Spam!
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
