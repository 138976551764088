import { Fragment } from 'react';
import { Box, Image, Flex, Text } from '@chakra-ui/react';
import type { HowWorksContent } from '@app/interfaces/PageContents';

type HowWorksSectionProps = {
  content: HowWorksContent;
};
export const HowWorksSection = ({
  content,
}: HowWorksSectionProps): JSX.Element => {
  return (
    <Flex
      background="green.300"
      w="full"
      h="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        maxW="71.25rem"
        color="#fff"
        gap="3rem"
        p={8}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex flex="1" direction="column" gap="1.5rem" px={6}>
          <Text
            fontSize={{ base: '1.2rem', lg: '1.9rem' }}
            fontWeight="normal"
            lineHeight="2.3rem"
          >
            {content.sectionTitle}
          </Text>

          {content.sectionContent?.map((content, index) => (
            <Flex key={index} direction="column" gap="1rem">
              <Text
                fontSize={{ base: '.95rem', lg: '1.05rem' }}
                textDecoration="underline"
                fontWeight="700"
              >
                {content.title}
              </Text>

              {content.descriptions.map((descriptionText, _) => (
                <Fragment key={_}>
                  <Text
                    fontSize={{ base: '.95rem', lg: '1.05rem' }}
                    dangerouslySetInnerHTML={{ __html: descriptionText }}
                  />
                </Fragment>
              ))}
            </Flex>
          ))}
          <Box
            textShadow="0px 0px 1rem #174717"
            as="a"
            fill="#FFF"
            href="#topo"
            cursor="pointer"
            background="transparent"
            alignSelf="center"
            backgroundImage="linear-gradient(110deg, green.350 0%, green.350 67%)"
            padding="1.3rem 2rem"
            boxShadow="0 0 0.95rem 0 #2EC52B"
            borderRadius={3}
          >
            <Text
              fontWeight="600"
              fontSize=".85rem"
              lineHeight="110%"
              color="#FFF"
              textAlign="center"
            >
              {content.buttonText}
            </Text>
          </Box>
        </Flex>
        <Box maxW="27.31rem" alignSelf="center">
          <Image
            src={content.imageUrl}
            alt="carol-e-moran"
            w={{ base: '330px', md: 'initial' }}
          />
          <Text fontSize=".6rem" mt={2} color="#FFF" textAlign="justify">
            {content.imageObservationText}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
