import { Flex, Text, Image, Heading } from '@chakra-ui/react';
import type { MentorsContent } from '@app/interfaces/PageContents';

type MentorsSectionProps = {
  content: MentorsContent;
};

export const MentorsSection = ({
  content,
}: MentorsSectionProps): JSX.Element => {
  return (
    <Flex
      background="white"
      direction="column"
      w="full"
      maxW="71.25rem"
      gap="3rem"
      h="full"
      py={4}
      px={8}
      color="green.350"
    >
      <Heading alignSelf="center" fontWeight="normal">
        {content.sectionTitle}
      </Heading>
      <Flex gap="1.9rem" direction={{ base: 'column', lg: 'row' }}>
        {content.mentorsSection?.map((mentorContent) => (
          <Flex
            key={mentorContent.mentorName}
            direction="column"
            gap="2rem"
            justifyContent="space-between"
          >
            <Image
              w="110px"
              alignSelf="center"
              h="110px"
              alt="avatar-mentor"
              borderRadius="full"
              src={mentorContent.mentorAvatarUrl}
            />
            <Text fontSize="1.45rem" fontWeight="bold">
              {mentorContent.mentorName}
            </Text>
            <Text textAlign="justify">{mentorContent.mentorBiography}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
