import type { GetStaticPropsResult } from 'next/types';
import { Flex } from '@chakra-ui/react';
import type {
  HowWorksContent,
  RecommendationsContent,
  PresentationMainContent,
  PresentationFormContent,
  MentorsContent,
  InvestorBenefitsContent,
} from '@app/interfaces/PageContents';
import { useFundamentalist } from '@app/services/graphql/hooks/useFundamentalist';
import { useRecommendedWallet } from '@app/services/graphql/hooks/useRecommendedWallet';
import { getHowWorksContent } from '@app/services/graphql/queries/graphcms/content/howWorksSection';
import { getInvestorBenefitsSectionContent } from '@app/services/graphql/queries/graphcms/content/investorBenefitsSection';
import { getMentorsSectionContent } from '@app/services/graphql/queries/graphcms/content/mentorsSection';
import { getPortfolioRecommendationsContent } from '@app/services/graphql/queries/graphcms/content/portfolioRecommendationSection';
import { getPresentationFormContent } from '@app/services/graphql/queries/graphcms/content/presentationFormSection';
import { getPresentationMainContent } from '@app/services/graphql/queries/graphcms/content/presentationSection';
import { useWalletContext } from '@app/context/WalletContext';
import { useSaveUtm } from '@app/hooks/useSaveUtm';
import { HowWorksSection } from '@app/components/Modules/HowWorksSection';
import { InvestorBenefitsSection } from '@app/components/Modules/InvestorBenefitsSection';
import { MentorsSection } from '@app/components/Modules/MentorsSection';
import { PortfolioRecommendationSection } from '@app/components/Modules/PortfolioRecommendationSection';
import { Presentation } from '@app/components/Modules/Presentation';
export const Home = ({
  howWorksContent,
  investorBenefitsContent,
  mentorContent,
  presentationFormContent,
  presentationMainContent,
  recommendationContent,
}: HomeProps) => {
  const { setRecommended, setTickers } = useWalletContext();
  useSaveUtm();
  useFundamentalist({
    onSuccess: ({ data }) => setTickers(data),
  });
  useRecommendedWallet({
    onSuccess: ({ wallets }) => setRecommended(wallets),
  });

  return (
    <Flex
      w="full"
      flexDirection="column"
      alignItems={{ base: 'unset', lg: 'center' }}
      gap={4}
      mt={{ base: '1rem', lg: '6.75rem' }}
    >
      <Presentation
        formContent={presentationFormContent}
        mainContent={presentationMainContent}
      />
      <PortfolioRecommendationSection content={recommendationContent} />
      <HowWorksSection content={howWorksContent} />
      <MentorsSection content={mentorContent} />
      <InvestorBenefitsSection content={investorBenefitsContent} />
    </Flex>
  );
};

export default Home;

type HomeProps = {
  presentationMainContent: PresentationMainContent;
  presentationFormContent: PresentationFormContent;
  recommendationContent: RecommendationsContent;
  howWorksContent: HowWorksContent;
  mentorContent: MentorsContent;
  investorBenefitsContent: InvestorBenefitsContent;
};

export const getStaticProps = async (): Promise<
  GetStaticPropsResult<HomeProps>
> => {
  const { presentationMainContent } = await getPresentationMainContent();

  const { presentationFormContent } = await getPresentationFormContent();

  const { howWorksContent } = await getHowWorksContent();

  const { recommendationContent } = await getPortfolioRecommendationsContent();

  const { investorBenefitsContent } = await getInvestorBenefitsSectionContent();

  const { mentorContent } = await getMentorsSectionContent();

  return {
    props: {
      presentationMainContent,
      presentationFormContent,
      howWorksContent,
      recommendationContent,
      investorBenefitsContent,
      mentorContent,
    },
  };
};
